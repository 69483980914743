import axios from "axios";

class axiosHelper {
  constructor() {
    this.setHeaders();
  }

  setHeaders() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.withCredentials = true;
  }

  verifyEmail(token: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/userplatform/verifyemail`, {
        params: {
          token: token,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  verifyNewEmail(token: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/userplatform/verifyChangeEmail`,
        {
          params: {
            token: token,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updatePassword(password: string, newPassword: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/userplatform/updatePassword`,
        { password, newPassword },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateEmail(email: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/userplatform/updateEmail`,
        { email },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  login(email: string, password: string) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/userplatform/login`,
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  logout() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/userplatform/logout`, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  register(email: string, password: string, username: string, uuid?: string) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/userplatform/register`, {
        email: email,
        password: password,
        username: username,
        uuid: uuid,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  // verifyFacebook(access_token: string) {
  //   return axios
  //     .post(
  //       `${process.env.REACT_APP_API_ENDPOINT}/auth/social/verifyFacebook`,
  //       { access_token: access_token }
  //     )
  //     .then((response) => console.log(response))
  //     .catch((error) => console.log(error));
  // }

  verifyToken(uuid?: string) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/auth/social/verify`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        return response.data;
      })
      .catch((err) => {
        console.error("Errore durante la verifica del token", err);
        throw err;
      });
  }

  facebookLogin(id: string, name: string, email: string, uuid: string) {
    return axios
      .post<FacebookLoginResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/social/facebook`,
        {
          id: id,
          name: name,
          email: email,
          uuid: uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error.response.data);
        return error.response.data;
      });
  }

  getLast5Exchanges() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/exchange/last5`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getLast5CompanyExchanges() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/exchange/company/last5`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getUserExchange(page: number) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/exchange/${page}`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompanyExchanges(page: number) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/exchange/company/${page}`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addUserImage(image: uploadImageData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addImage`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addUserImageGod(image: uploadImageData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addImage/${uuid}`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeUserImage() {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/removeImage`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeUserImageGod(uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/removeImage/${uuid}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addUserLogo(image: uploadImageData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addLogo`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeUserLogo() {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/removeLogo`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addCompanyLogo(image: uploadImageData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/platform/addLogo`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeCompanyLogo() {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/platform/removeLogo`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updatePersonalInformations(data: updatePersonalInformationData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/personalInformations`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updatePersonalInformationsGod(
    data: updatePersonalInformationData,
    uuid: string
  ) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/personalInformations/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateWorkInformations(data: updateWorkInformationData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/workInformations`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateWorkInformationsGod(data: updateWorkInformationData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/workInformations/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateSocials(data: updateSocialsInformationData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/updateSocialInfo`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateSocialsGod(data: updateSocialsInformationData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/updateSocialInfo/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateLinks(data: updateLinksData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/updateLinks`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  updateLinksGod(data: updateLinksData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/updateLinks/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addUserFile(image: uploadFileData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addFile`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  addUserFileGod(image: uploadFileData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addFile/${uuid}`,
        image,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeUserFile(file_number: number) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/removeFile`,
        { file_number },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  removeUserFileGod(file_number: number, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/removeFile/${uuid}`,
        { file_number },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCounters() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/counter/platform`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompanyCounters() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/counter/platform/company`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  sendUuid(uuid: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/social/addUuid/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  createContact() {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/createContact`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  sendNameAndSurname(data: AddNameSurnameData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addNameSurname`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  sendRoleAndCompany(data: AddCompanyData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addCompanyRole`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  sendPrefixAndPhone(data: AddPrefixPhoneData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/addPrefixPhone`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  changeMode(data: ChangeModeData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/changeMode`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  changeModeGod(data: ChangeModeData, uuid: string) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/changeMode/${uuid}`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  deleteContact(uuid: string) {
    return axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/contact/platform/${uuid}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  deleteCounter() {
    return axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/counter/platform`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  deleteExchanges() {
    return axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/exchange/platform`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  deleteUser() {
    return axios
      .delete(`${process.env.REACT_APP_API_ENDPOINT}/userplatform/platform`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  getStripeSession(data: ProductIds) {
    return axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/stripe/create-checkout-session`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompany() {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/company`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  }

  getCompanyContacts(page: number) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/company/contacts/${page}`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  searchContactBySurname(surname: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/search-surname/${surname}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  searchContactByUuid(uuid: string) {
    return axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/company/search-uuid/${uuid}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContactByUuid(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`, {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateCompanyInfo(data: updateCompanyData) {
    return axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/company/platform/informations`,
        { data },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new axiosHelper();
