import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";

const VerifyEmail: React.FC = () => {
  const params = useParams();

  const redirect = useNavigate();

  // useEffect(() => {
  //   console.log(params.token);
  // }, []);

  useEffect(() => {
    veifyEmail();
  }, []);

  const veifyEmail = async () => {
    if (params.token) {
      await axiosHelper
        .verifyEmail(params.token)
        .then((response) => {
          redirect("/Login");
        })
        .catch((error) => console.log(error));
    } else {
      console.log("no token");
    }
  };

  return (
    <>
      <p></p>
    </>
  );
};

export default VerifyEmail;
