import React, { useState } from "react";

import styles from "./FileComponent.module.scss";

import axiosHelper from "../../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Dropzone from "react-dropzone";
import BusinessPlatformStore, {
  updateUserFiles,
} from "../../store/BusinessPlatformStore";
import { useStoreState } from "pullstate";
import { getPlatformContact, getPlatformUser } from "../../store/Selector";

interface FileComponentProps {
  url: string;
  filenumber: number;
  filename: string;
}

const FileComponent: React.FC<FileComponentProps> = (props) => {
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);
  const contact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [file, setFile] = useState<File>();
  const [open, setOpen] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [fileName, setFileName] = useState(
    props.filename !== null ? props.filename : ""
  );

  const onDropFile = async (e: any) => {
    // console.log(e?.[0]);
    setFile(e?.[0]);
  };

  const uploadFile = async () => {
    if (file && fileName) {
      let uploadLogo: uploadFileData = {
        image: file,
        file_number: props.filenumber,
        label: fileName,
      };
      setLoadingFile(true);
      if (user.user_type !== "G") {
        await axiosHelper.addUserFile(uploadLogo).then((response) => {
          // console.log(response);
          updateUserFiles(response, props.filenumber);
          setFile(undefined);
          setLoadingFile(false);
        });
      } else {
        await axiosHelper
          .addUserFileGod(uploadLogo, contact.uuid)
          .then((response) => {
            // console.log(response);
            updateUserFiles(response, props.filenumber);
            setFile(undefined);
            setLoadingFile(false);
          });
      }
    }
  };

  const removeFile = async () => {
    if (user.user_type !== "G") {
      await axiosHelper
        .removeUserFile(props.filenumber)
        .then((response) => {
          // console.log(response);
          updateUserFiles("", props.filenumber);
          setFileName("");
        })
        .catch((error) => {});
    } else {
      await axiosHelper
        .removeUserFileGod(props.filenumber, contact.uuid)
        .then((response) => {
          console.log(response);
          updateUserFiles("", props.filenumber);
          setFileName("");
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      <div
        className={`d-flex flex-row  align-items-center justify-content-start col-12 ${styles.fileRow} mt-4`}
        onClick={() => setOpen(!open)}
      >
        File {props.filenumber}: {fileName}
      </div>
      {open && (
        <div className="d-flex flex-row align-items-start justify-content-start col-12">
          <div className={`${styles.pdfContainer} col-xl-3  col-4 mt-4 `}>
            <div
              className={`${styles.pdfBox} `}
              style={{
                border: `${
                  props.url !== "" && props.url !== null
                    ? "1px solid black"
                    : "2px dashed #9d9d9d"
                }`,
              }}
            >
              {props.url !== "" && props.url !== null ? (
                <>
                  {/* <img
                    src={props.url}
                    className="bg-white"
                    onClick={() => window.open(pdfsample, "_blank")}
                  /> */}

                  {/* <div className={`${styles.zoomBtn}`}>
                    <img src={zoom} />
                  </div> */}
                  <FontAwesomeIcon
                    icon={faFileAlt as IconProp}
                    style={{ fontSize: "60px" }}
                  />
                  <div
                    className={`${styles.deleteBtn}`}
                    onClick={() => removeFile()}
                  >
                    <FontAwesomeIcon icon={faXmark as IconProp} />
                  </div>
                </>
              ) : (
                <>
                  {!loadingFile ? (
                    file === undefined && (
                      <Dropzone
                        onDrop={onDropFile}
                        accept={{
                          "image/*": [],
                          "application/pdf": [],
                          "application/msword": [],
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            [".docx"], // File Word (.docx)
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                            [".pptx"], // File PowerPoint (.pptx)
                        }}
                        maxSize={31457280}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className={`${styles.inputLogoContainer} h-100 col-12 d-flex align-items-center justify-content-center color-black text-center p-4`}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span className="text-s">
                              Drag and drop an image or <br />
                              <span
                                className="ms-1 color-dark_grey_50 cursor-pointer"
                                style={{ textDecoration: "underline" }}
                              >
                                choose a file
                              </span>
                              <br />
                              (.jpg/.png/.jpeg/.pdf)
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    )
                  ) : (
                    <>
                      <div className={`${styles.loading} `}> </div>
                    </>
                  )}
                  {file !== undefined && !loadingFile && (
                    <>
                      <div className="d-flex flex-column align-items-center justify-content-center col-12">
                        <FontAwesomeIcon
                          icon={faFileAlt as IconProp}
                          style={{ fontSize: "60px" }}
                        />
                        <div className="mt-3">{file?.name}</div>
                        <div
                          className={`${styles.deleteBtn}`}
                          onClick={() => setFile(undefined)}
                        >
                          <FontAwesomeIcon icon={faXmark as IconProp} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`col-xl-9 col-9  mt-4 ${styles.fileNameRow} d-flex flex-column align-items-start justify-content-center p-5`}
          >
            <span> File label </span>
            <input
              type="text"
              value={fileName}
              placeholder="Insert a label for your file"
              onChange={(e) => setFileName(e.target.value)}
              className={`${styles.inputField} col-12 mt-3`}
            />
            <span className={`col-12 ${styles.rowInput}`}></span>
            <button
              className={` mt-7 col-xl-3 col-5  ${styles.uploadBtn} text-xxl fw-bold`}
              onClick={() => uploadFile()}
            >
              Upload
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FileComponent;
