import React from "react";
import styles from "./PageSkeleton.module.scss";
import { useNavigate } from "react-router-dom";

import BusinessPlatformStore, {
  setCurrentMenu,
  setUserExchanges,
} from "../../store/BusinessPlatformStore";
import { useStoreState } from "pullstate";
import {
  getCurrentMenu,
  getPlatformUser,
  getUserExchanges,
} from "../../store/Selector";

import home from "../../assets/home.svg";
import homeGreen from "../../assets/homeGreen.svg";
import edit from "../../assets/edit.svg";
import editGreen from "../../assets/editGreen.svg";
import users from "../../assets/users.svg";
import usersGreen from "../../assets/usersGreen.svg";
import analytics from "../../assets/analytics.svg";
import analyticsGreen from "../../assets/analyticsGreen.svg";
import userGreen from "../../assets/userGreen.svg";
import userIcon from "../../assets/userGrey.svg";
import axiosHelper from "../../helpers/axiosHelper";

interface PageMenuProps {}

const PageMenu: React.FC<PageMenuProps> = (props) => {
  const currentMenu = useStoreState(BusinessPlatformStore, getCurrentMenu);
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);

  const exchanges = useStoreState(BusinessPlatformStore, getUserExchanges);
  const redirect = useNavigate();

  const goToExchange = async () => {
    // console.log(user.user_type);
    if (exchanges.length === 0) {
      if (user.user_type === "U" || user.user_type === "E") {
        await axiosHelper.getUserExchange(0).then((response) => {
          // console.log(response);
          setUserExchanges(response.exchanges);
          redirect("/exchange-list");
          setCurrentMenu("Exchange");
        });
      } else {
        await axiosHelper.getCompanyExchanges(0).then((response) => {
          // console.log(response);
          setUserExchanges(response.exchanges);
          redirect("/exchange-list");
          setCurrentMenu("Exchange");
        });
      }
    } else {
      redirect("/exchange-list");
      setCurrentMenu("Exchange");
    }
  };

  const handleRedirect = (
    currentMenu: string,
    redirectUser: string,
    redirectGod: string
  ) => {
    setCurrentMenu(currentMenu);
    if (user.user_type === "G") {
      redirect(`${redirectGod}`);
    } else {
      redirect(`${redirectUser}`);
    }
  };

  return (
    <>
      <div
        className={`d-flex d-xl-none flex-row align-items-center justify-content-around col-12 ${styles.mobileMenu}`}
      >
        <div
          className="col-2  d-flex flex-row align-items-center justify-content-center"
          onClick={() => {
            handleRedirect("Home", "/", "/");
          }}
        >
          <img
            alt="homeIcon"
            src={currentMenu === "Home" ? homeGreen : home}
            className={`${styles.icon}  me-4`}
          />
        </div>
        <div
          className="col-2 d-flex flex-row align-items-center justify-content-center"
          onClick={() => {
            handleRedirect("Edit Profile", "/edit-profile", "/edit-company");
          }}
        >
          <img
            alt="editProfileIcon"
            src={
              currentMenu === "Edit Profile" ||
              currentMenu === "Edit Company" ||
              currentMenu === "Contact List"
                ? editGreen
                : edit
            }
            className={`${styles.icon}  me-4`}
          />
        </div>
        <div
          className="col-2 d-flex flex-row align-items-center justify-content-center"
          onClick={() => {
            goToExchange();
          }}
        >
          <img
            alt="exchangeIcon"
            src={currentMenu === "Exchange" ? usersGreen : users}
            className={`${styles.icon}  me-4`}
          />
        </div>
        <div
          className="col-2 d-flex flex-row align-items-center justify-content-center"
          onClick={() => {
            handleRedirect("Analytics", "/analytics", "/analytics");
          }}
        >
          <img
            alt="analyticsIcon"
            src={currentMenu === "Analytics" ? analyticsGreen : analytics}
            className={`${styles.icon}  me-4`}
          />
        </div>
        <div
          className="col-2 d-flex flex-row align-items-center justify-content-center"
          onClick={() => {
            handleRedirect("Account Settings", "/user-info", "/user-info");
          }}
        >
          <img
            alt="setingsIcon"
            src={currentMenu === "Account Settings" ? userGreen : userIcon}
            className={`${styles.icon}  me-4`}
          />
        </div>
      </div>
      <div
        className={` d-xl-flex d-none flex-row   col-2 `}
        style={{ height: "90vh" }}
      >
        <div
          className={`col-2 h-100  color-white  d-flex flex-column align-items-start justify-content-start p-5 ${styles.main}`}
        >
          {/* <div
            className="d-flex flex-row col-12  align-items-center justify-content-between cursor-pointer"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span>Personal profile</span>
            <span>
              <FontAwesomeIcon
                icon={(menuOpen ? faAngleDown : faAngleUp) as IconProp}
                style={{ fontSize: "17px" }}
              />
            </span>
          </div> */}
          <div
            className="d-flex flex-row align-items-center justify-content-start mt-5 col-12  fw-bold cursor-pointer "
            onClick={() => {
              handleRedirect("Home", "/", "/");
            }}
          >
            <img
              alt="homeIcon"
              src={currentMenu === "Home" ? homeGreen : home}
              className={`${styles.icon}  me-4`}
            />
            Home
          </div>
          {user.user_type !== "G" && (
            <div
              className="d-flex flex-row align-items-center justify-content-start mt-5 col-12 fw-bold  cursor-pointer"
              onClick={() => {
                handleRedirect(
                  "Edit Profile",
                  "/edit-profile",
                  "/edit-company"
                );
              }}
            >
              <img
                alt="editProfileIcon"
                src={currentMenu === "Edit Profile" ? editGreen : edit}
                className={`${styles.icon}  me-4`}
              />
              Edit profile
            </div>
          )}
          {user.user_type === "G" && (
            <div
              className="d-flex flex-row align-items-center justify-content-start mt-5 col-12 fw-bold  cursor-pointer"
              onClick={() => {
                handleRedirect(
                  "Edit Company",
                  "/edit-profile",
                  "/edit-company"
                );
              }}
            >
              <img
                alt="editProfileIcon"
                src={
                  currentMenu === "Edit Company" ||
                  currentMenu === "Contact List"
                    ? editGreen
                    : edit
                }
                className={`${styles.icon}  me-4`}
              />
              Edit Company
            </div>
          )}
          <div
            className="d-flex flex-row align-items-center justify-content-start mt-5 col-12 fw-bold  cursor-pointer"
            onClick={() => {
              goToExchange();
              // setCurrentMenu("exchange");
              // redirect("/exchange-list");
            }}
          >
            <img
              alt="exchangeIcon"
              src={currentMenu === "Exchange" ? usersGreen : users}
              className={`${styles.icon}  me-4`}
            />
            Exchanges
          </div>
          <div
            className="d-flex flex-row align-items-center justify-content-start mt-5 col-12 fw-bold  cursor-pointer"
            onClick={() => {
              handleRedirect("Analytics", "/analytics", "/analytics");
            }}
          >
            <img
              alt="analyticsIcon"
              src={currentMenu === "Analytics" ? analyticsGreen : analytics}
              className={`${styles.icon}  me-4`}
            />
            Analytics
          </div>
          <div
            className="d-flex flex-row align-items-center justify-content-start mt-5 col-12 fw-bold  cursor-pointer"
            onClick={() => {
              handleRedirect("Account Settings", "/user-info", "/user-info");
            }}
          >
            <img
              alt="settingsIcon"
              src={currentMenu === "Account Settings" ? userGreen : userIcon}
              className={`${styles.icon}  me-4`}
            />
            Account settings
          </div>
        </div>
      </div>
    </>
  );
};

export default PageMenu;
