import React, { useEffect, useState } from "react";

const ResetPwd: React.FC = () => {
  const [password, setPassword] = useState("");
  const [passwordRpt, setPasswordRpt] = useState("");
  const [pwdError, setPwdError] = useState("");

  const resetPwd = async (e: any) => {
    e.preventDefault();
    // if (password === passwordRpt) {
    //   if (params.token && params.email) {
    //     await axiosHelper
    //       .updatePassword(params.token, password, params.email)
    //       .then((response) => {
    //         redirect("/Login");
    //       });
    //   } else {
    //     console.log("no token");
    //   }
    // } else {
    //   setPwdError("Passwords not match");
    // }
  };

  useEffect(() => {
    // console.log(pwdError);
  }, [pwdError]);

  return (
    <>
      <div>
        <form onSubmit={resetPwd}>
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <input
            type="text"
            value={passwordRpt}
            onChange={(e) => setPasswordRpt(e.target.value)}
            placeholder="Password"
          />
          <button type="submit"> cambia password</button>
        </form>
      </div>
      {pwdError}
    </>
  );
};

export default ResetPwd;
