import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";
import { useEffect } from "react";

function App() {
  return (
    <Router>
      <Container />
    </Router>
  );
}

export default App;
