import { createSelector } from "reselect";
import { IBusinessPlatformStore } from "./BusinessPlatformStore";
const getBusinessPlatformState = (state: IBusinessPlatformStore) => state;

export const getPlatformUser = createSelector(
  getBusinessPlatformState,
  (state) => state.user
);

export const getPlatformContact = createSelector(
  getBusinessPlatformState,
  (state) => state.contact
);

export const getCurrentMenu = createSelector(
  getBusinessPlatformState,
  (state) => state.currentMenu
);

export const getLast5Exchanges = createSelector(
  getBusinessPlatformState,
  (state) => state.last5Exchanges
);

export const getUserExchanges = createSelector(
  getBusinessPlatformState,
  (state) => state.userExchanges
);

export const getExchangePageNumber = createSelector(
  getBusinessPlatformState,
  (state) => state.exchangePageNumber
);

export const getCompanyContactsPageNumber = createSelector(
  getBusinessPlatformState,
  (state) => state.companyContactsPageNumber
);

export const getUserCounter = createSelector(
  getBusinessPlatformState,
  (state) => state.userCounter
);

export const getCompany = createSelector(
  getBusinessPlatformState,
  (state) => state.company
);

export const getCompanyContacts = createSelector(
  getBusinessPlatformState,
  (state) => state.companyContacts
);
