import React, { useState } from "react";

import styles from "./ContactRowBig.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { setPlatformContact } from "../../store/BusinessPlatformStore";

interface ExchangeRowBigProps {
  name: string;
  surname: string;
  role: string;
  uuid: string;
  email: string;
}

const ContactRowBig: React.FC<ExchangeRowBigProps> = (props) => {
  const redirect = useNavigate();

  const goToEditContact = async () => {
    await axiosHelper.getContactByUuid(props.uuid).then((response) => {
      // console.log(response);
      setPlatformContact(response);
      redirect("/edit-profile");
    });
  };

  return (
    <>
      <div
        className=" d-flex flex-row align-items-center justify-content-start  col-12 color-black "
        style={{ height: "50px", borderBottom: "1px solid black" }}
      >
        <span className="col-xl-3 col-4 sub-title h-100 d-flex flex-row align-items-center justify-content-start">
          {props.uuid}
        </span>
        <span className="col-xl-3 col-3   text-xxl overflow-hidden h-100 d-flex flex-row align-items-center justify-content-start">
          {props.name} {props.surname}
        </span>
        <span className="col-xl-2  d-none col-3 text-xxl  overflow-hidden h-100 d-xl-flex flex-row align-items-center justify-content-start">
          {props.role}
        </span>
        <span className="col-xl-3 col-3 text-xxl overflow-hidden h-100 d-flex flex-row align-items-center justify-content-start">
          <a
            href={`mailto:${props.email}`}
            style={{ textDecoration: "underline" }}
            className="color-black"
          >
            {props.email}
          </a>
        </span>
        <span className="col-xl-1 col-2   text-xxl overflow-hidden d-flex flex-row align-items-center justify-content-center h-100 ">
          <div
            className={`d-flex flex-row align-items-center justify-content-center col-xl-8 col-10  cursor-pointer ${styles.detailsBtn} `}
            onClick={() => goToEditContact()}
          >
            <FontAwesomeIcon
              icon={faPencil as IconProp}
              style={{ fontSize: "1.5rem" }}
            />
          </div>
        </span>
      </div>
    </>
  );
};

export default ContactRowBig;
