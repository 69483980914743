import React from "react";

const StripeSuccess: React.FC = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center color-white bg-white">
      Success dioboe
      <a href="/"> home </a>
    </div>
  );
};

export default StripeSuccess;
