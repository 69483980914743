import React, { useCallback, useEffect, useState } from "react";
import styles from "./Onboarding.module.scss";
import axiosHelper from "../../helpers/axiosHelper";
import Dropzone from "react-dropzone";
import { useStoreState } from "pullstate";
import BusinessPlatformStore, {
  updateUserImage,
} from "../../store/BusinessPlatformStore";
import { getPlatformContact } from "../../store/Selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface AddCompanyProps {
  success: () => void;
}

const AddImage: React.FC<AddCompanyProps> = (props) => {
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [image, setImage] = useState<File>();
  const [loadingImage, setLoadingImage] = useState(false);

  const uploadImage = useCallback(async () => {
    if (image) {
      let uploadImage: uploadImageData = { image: image };
      setLoadingImage(true);
      await axiosHelper.addUserImage(uploadImage).then((response) => {
        // console.log(response);
        updateUserImage(response);
        setImage(undefined);
        setLoadingImage(false);
      });
    }
  }, [image]);

  useEffect(() => {
    if (image !== undefined) {
      uploadImage();
    }
  }, [image, uploadImage]);

  const removeImage = async () => {
    await axiosHelper
      .removeUserImage()
      .then((response) => {
        // console.log(response);
        updateUserImage("");
      })
      .catch((error) => {});
  };
  const onDropImage = async (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  return (
    <>
      <div
        className={` d-flex flex-column  align-items-center justify-content-start  col-12  ${styles.container}`}
      >
        <div className={`${styles.titleRow} `}>
          <span className="onboarding-title fw-bold  col-12 text-center">
            Do you want to add your contact image?
          </span>
        </div>
        <div
          className={`col-6 d-flex flex-row align-items-center justify-content-center mt-10 ${styles.editImageContainer}`}
        >
          <div
            className={`col-12  d-flex flex-row align-items-center justify-content-center ${styles.imageContainer}`}
          >
            {userContact.imageurl ? (
              <img alt="userImage" src={userContact.imageurl} />
            ) : (
              <div
                className={`${styles.imageContainerEmpty} d-flex flex-row align-items-center justify-content-center`}
              >
                {!userContact.imageurl && !loadingImage && (
                  <Dropzone
                    onDrop={onDropImage}
                    accept={{
                      "image/jpeg": [],
                      "image/png": [],
                      "image/jpg": [],
                      // "application/pdf": [],
                    }}
                    maxSize={31457280}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className={`${styles.inputImageContainer} h-100 col-12 d-flex align-items-center justify-content-center color-white text-center p-4`}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <span className="text-s">
                          Drag and drop an image or
                          <span
                            className="ms-1 color-dlcgreen cursor-pointer"
                            style={{ textDecoration: "underline" }}
                          >
                            choose a file
                          </span>
                          <br />
                          (.jpg/.png/.jpeg)
                        </span>
                      </div>
                    )}
                  </Dropzone>
                )}
                {loadingImage && <div className={`${styles.loading} `}> </div>}
                {/* <img src={avatar} /> */}
              </div>
            )}
          </div>
          {userContact.imageurl && (
            <div
              className={`${styles.removeImg} cursor-pointer `}
              onClick={() => removeImage()}
            >
              <FontAwesomeIcon
                icon={faXmark as IconProp}
                // style={{ fontSize: "20px" }}
              />
            </div>
          )}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center col-12">
          <button
            className={`${
              userContact.imageurl
                ? styles.submitBtn
                : styles.submitBtnDisactive
            } col-12 sub-title`}
            onClick={() => props.success()}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default AddImage;
