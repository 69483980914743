import React, { useEffect, useState } from "react";
import styles from "./Login.module.scss";

import axiosHelper from "../../helpers/axiosHelper";
import logo from "../../assets/LOGO_WHITE.png";
import parse from "html-react-parser";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useNavigate, useParams } from "react-router-dom";
import { useStoreState } from "pullstate";
import BusinessPlatformStore, {
  setPlatformContact,
  setPlatformUser,
} from "../../store/BusinessPlatformStore";
import { getPlatformUser } from "../../store/Selector";

interface FacebookResponse {
  name: string;
  email: string;
  picture: { data: { url: string } };
}

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const redirect = useNavigate();

  const params = useParams();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoginError("");

    try {
      await axiosHelper
        .login(email, password)
        .then((response) => {
          setPlatformContact(response.data.usercontact);
          setPlatformUser(response.data.user);
          // console.log(response.data.usercontact);
          if (
            !response.data.usercontact.name &&
            !response.data.usercontact.surname
          ) {
            redirect("/onboarding");
          } else {
            redirect("/");
          }
        })
        .catch((error) => {
          // console.log("error login");
          // console.log(error.response.data.msg);
          setLoginError(error.response.data.msg);
        });

      // // console.log(response);
      // // console.log(document.cookie);

      // Salva il token nel localStorage
      // alert("Login effettuato con successo");
    } catch (error) {
      console.error("Errore di login", error);
      setLoginError(
        "Something goes wrong<br/><br/> Check your credentials and try again."
      );
      // alert("Errore di login, controlla le credenziali");
    }
  };

  const handleGoogleLogin = () => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/social/google`,
      "_self"
    );
  };

  useEffect(() => {
    // Verifica il token JWT nel backend
    verifyToken();
  }, []);

  const verifyToken = async () => {
    let uuid = "";
    if (params.uuid) {
      uuid = params.uuid;
    }
    // console.log(uuid);
    await axiosHelper
      .verifyToken(uuid)
      .then((response) => {
        // console.log(response);
        if (response.user) setPlatformUser(response.user);
        if (response.contact) setPlatformContact(response.contact);

        //se contact non ha nome e cognome, vai a fare onboarding, altrimenti vai a home
        if (
          response.contact.name === null &&
          response.contact.surname === null
        ) {
          redirect("/onboarding");
        }
        redirect("/");
        // window.history.replaceState({}, document.title, "/"); // Rimuovi il token dall'URL);}
      })
      .catch((error) => {
        // console.log(error);
        if (error.status === 400) {
          // console.log("signin");
        }
      });
  };

  // const handleFacebookLogin = () => {
  //   window.open(
  //     `${process.env.REACT_APP_API_ENDPOINT}/auth/social/facebook`,
  //     "_self"
  //   );
  // };

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start  ${styles.boxContainer}`}
        >
          <div className={`${styles.header} col-12`}>
            <span
              className={`${styles.logoContainer} d-flex flex-row align-items-center justify-content-center col-xl-3 col-7  mt-xl-10 mt-7  `}
            >
              <img src={logo} />
            </span>

            <p className="mt-10 light-title fw-bold col-xl-4 col-8 text-center  ">
              Login
            </p>
            {/* <p className="mt-3 sub-title col-xl-4 col-8 text-center mb-4  "> */}
            {/* Don't have an account?<br/> <span className="color-dlcgreen cursor-pointer" style={{textDecoration:'underline'}} onClick={() => redirect('/register')}> Register</span> */}
            {/* </p> */}
          </div>

          <div className={`col-xl-4 col-12 ${styles.content}  `}>
            <form onSubmit={handleLogin} className="col-12  ">
              <div className="d-flex flex-column align-items-center justify-content-start col-12 mt-10 ">
                <span className="d-flex flex-row col-12 align-items-center justify-content-center text-center text-xxl mb-5">
                  {parse(loginError)}
                </span>
                <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3 mt-4">
                  Email
                </span>
                <input
                  type="email"
                  placeholder="Email"
                  className={`${styles.inputField}  col-12`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span className="sub-title d-flex flex-row align-items-center justify-content-start col-12 mb-3  mt-4">
                  Password
                </span>
                <input
                  type="password"
                  placeholder="Password"
                  className={`${styles.inputField} col-12 `}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <button
                  type="submit"
                  className={`${
                    email && password
                      ? styles.submitBtn
                      : styles.submitBtnDisactive
                  }  sub-title  mt-5 col-12 `}
                  disabled={!email.length && !password.length}
                >
                  Login
                </button>
              </div>
            </form>

            <div className="col-12  d-flex flex-column justify-content-center align-items-center mt-7 ">
              {/* <div className="col-12 ">
                <FacebookLoginButton
                  style={{ borderRadius: "20px" }}
                  onClick={handleFacebookLogin}
                />
              </div> */}
              <div className="col-12 ">
                <GoogleLoginButton
                  style={{ borderRadius: "20px" }}
                  onClick={handleGoogleLogin}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
