import React, { useState } from "react";

import styles from "./DeleteAccountPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import parse from "html-react-parser";
import { useStoreState } from "pullstate";
import BusinessPlatformStore from "../../store/BusinessPlatformStore";
import { getPlatformUser } from "../../store/Selector";

interface ExchangeRowBigProps {
  open: boolean;
  close: () => void;
}

const DeleteAccountPopUp: React.FC<ExchangeRowBigProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const platformUser = useStoreState(BusinessPlatformStore, getPlatformUser);

  const deleteAccount = async () => {
    await axiosHelper.deleteCounter();
    await axiosHelper.deleteExchanges();
    await axiosHelper.deleteContact(platformUser.contact_uuid);
    await axiosHelper.deleteUser();
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => {
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content}  col-12 `}>
              <span className="d-flex flex-row col-12 align-items-center justify-content-center light-title fw-bold mt-3">
                Delete Account
              </span>
              <div className="d-flex flex-column align-items-center justify-content-start mt-7 col-12 ">
                <span className="sub-title col-12 d-flex flex-row align-items-center justify-content-center">
                  Are you sure to delete your account?
                </span>
                <span className="text-xxl col-12 d-flex flex-row align-items-center justify-content-center mt-5">
                  Your login, contact, exchanges and analytics will be deleted.
                </span>
                <span className="text-xxl col-12 d-flex flex-row align-items-center justify-content-center mt-5">
                  If you have our NFC Business Card, it will not works anymore.
                </span>

                <span
                  className={`${styles.submitBtn} d-flex flex-row col-4 mt-7 align-items-center justify-content-center sub-title`}
                  onClick={() => deleteAccount()}
                >
                  Delete Account
                </span>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccountPopUp;
