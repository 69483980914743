import React, { useEffect, useState } from "react";

import styles from "./AboutWorkPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import BusinessPlatformStore, {
  setPlatformContact,
} from "../../store/BusinessPlatformStore";
import { useStoreState } from "pullstate";
import { getPlatformContact, getPlatformUser } from "../../store/Selector";

interface AboutWorkPopUpProps {
  role: string;
  company: string;
  workmail: string;
  website: string;
  workphone: string;
  workprefix: string;
  open: boolean;
  close: () => void;
}

const AboutWorkPopUp: React.FC<AboutWorkPopUpProps> = (props) => {
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);
  const contact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [popup, setPopup] = useState(false);
  const [role, setRole] = useState(props.role);
  const [company, setCompany] = useState(props.company);
  const [workmail, setWorkmail] = useState(props.workmail);
  const [workphone, setWorkphone] = useState(props.workphone);
  const [workprefix, setWorkprefix] = useState(props.workprefix);
  const [website, setWebsite] = useState(props.website);
  const [workprefixError, setWorkprefixError] = useState(false);

  const handleWebsite = (e: string) => {
    // console.log(e.startsWith("https://"));
    // console.log(e.startsWith("http://"));
    if (e) {
      if (!e.startsWith("https://") && !e.startsWith("http://")) {
        return "https://" + e;
      } else {
        return e;
      }
    } else {
      return "";
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const verifiedWebsite = handleWebsite(website);
    // console.log("submit");
    let data: updateWorkInformationData = {
      role: role,
      workmail: workmail,
      company: company,
      website: verifiedWebsite,
      workphone: workphone,
      workprefix: workprefix,
    };
    // console.log(data);
    if (workprefix && workprefix.length < 2) {
      setWorkprefixError(true);
    } else {
      if (user.user_type !== "G") {
        await axiosHelper
          .updateWorkInformations(data)
          .then((response) => {
            // console.log(response.updated);
            setPlatformContact(response.updated);
            props.close();
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        await axiosHelper
          .updateWorkInformationsGod(data, contact.uuid)
          .then((response) => {
            // console.log(response.updated);
            setPlatformContact(response.updated);
            props.close();
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
  };

  const handlePrefix = (e: string) => {
    if (!e.startsWith("+")) {
      setWorkprefix("+" + e);
    } else {
      setWorkprefix(e);
    }
  };

  useEffect(() => {
    workprefix && workprefix.length > 1 && setWorkprefixError(false);
  }, [workprefix]);
  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 pt-5 pt-xl-2">
                <span className="light-title fw-bold">
                  About work informations
                </span>
              </div>
              <form onSubmit={handleSubmit} className="col-12">
                <div className="d-flex flex-column align-items-start justify-content-start mt-7  col-12">
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start  ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Company
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="text"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={company}
                        required
                        placeholder="Insert your company name"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Role
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="text"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={role}
                        placeholder="Insert your role at the company "
                        onChange={(e) => setRole(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Work mail
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="email"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={workmail}
                        placeholder="Insert your work mail"
                        onChange={(e) => setWorkmail(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Work phone
                    </span>

                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <span
                        className={`col-3 d-flex flex-row align-items-center justify-content-center ${styles.prefix}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5 col-3  ${styles.inputField}`}
                          value={workprefix}
                          placeholder=" prefix "
                          onChange={(e) => handlePrefix(e.target.value)}
                        />
                      </span>
                      <input
                        type="text"
                        className={`ps-xl-5 col-9  ${styles.inputField}`}
                        value={workphone}
                        placeholder="mobile number"
                        onChange={(e) => setWorkphone(e.target.value)}
                      />
                    </span>
                  </div>
                  {workprefixError && (
                    <div
                      className="d-flex flex-row align-items-center justify-content-center col-12 text pt-5 text-center"
                      style={{ color: "red" }}
                    >
                      <span className="col-8">
                        Please insert the prefix of your work phone number
                      </span>
                    </div>
                  )}
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Website
                    </span>

                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="text"
                        className={`ps-xl-5 col-3  ${styles.inputField}`}
                        value={website}
                        placeholder="Insert website with https://..."
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5 ">
                    <button
                      type="submit"
                      className={`${styles.submitBtn} col-xl-3 col-5 text-xxl fw-bold`}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AboutWorkPopUp;
