import React, { useCallback, useEffect, useState } from "react";

import BusinessPlatformStore, {
  setCurrentMenu,
  setUserCounter,
} from "../../store/BusinessPlatformStore";

import styles from "./Analytics.module.scss";
import { useStoreState } from "pullstate";
import { getPlatformContact, getUserCounter } from "../../store/Selector";

import foglie from "../../assets/foglie.svg";
import wood from "../../assets/wood.svg";
import water from "../../assets/water.svg";
import energy from "../../assets/energy.svg";
import co2 from "../../assets/co2.svg";
import tree from "../../assets/albero1.png";
import axiosHelper from "../../helpers/axiosHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Analytics: React.FC = () => {
  const userCounter = useStoreState(BusinessPlatformStore, getUserCounter);
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [paperCardSaved, setPaperCardSaved] = useState(0);
  const [woodSaved, setWoodSaved] = useState(0);
  const [waterSaved, setWaterSaved] = useState(0);
  const [enegrySaved, setEnergySaved] = useState(0);
  const [co2Saved, setCO2Saved] = useState(0);

  const calculateSaved = useCallback(() => {
    const paperCard =
      parseInt(userCounter.exchange_number) +
      parseInt(userCounter.add_contact_click);
    console.log(userCounter.exchange_number);
    console.log(userCounter.add_contact_click);
    setPaperCardSaved(paperCard);
    if (paperCard > 0) {
      setWoodSaved(Math.round(paperCard * 0.0125 * 1000) / 1000);
      setWaterSaved(Math.round(paperCard * 0.075 * 100) / 100);
      setEnergySaved(Math.round(paperCard * 0.01 * 100) / 100);
      setCO2Saved(Math.round(paperCard * 0.0063 * 1000) / 1000);
    } else {
      setWoodSaved(0);
      setWaterSaved(0);
      setEnergySaved(0);
      setCO2Saved(0);
    }
  }, [userCounter]);

  useEffect(() => {
    if (userCounter.contact_uuid === "") {
      getCounter();
    } else {
      calculateSaved();
    }
  }, [userCounter, calculateSaved]);

  const getCounter = async () => {
    await axiosHelper.getCounters().then((response) => {
      console.log(response.counter);
      setUserCounter(response.counter);
    });
  };

  useEffect(() => {
    setCurrentMenu("Analytics");
  }, []);
  return (
    <>
      <div
        className={` d-flex flex-column align-items-start justify-content-start col-12 ${styles.firstDiv}`}
      >
        <div className="d-flex flex-row align-items-center justify-content-between col-12">
          <div className="d-flex flex-row align-items-center justify-content-start col-xl-8 col-12   ">
            <span className="  ">
              {userContact.imageurl ? (
                <img
                  alt="userImage"
                  src={userContact.imageurl}
                  className={`${styles.profileImg}  `}
                />
              ) : (
                <div
                  className={`d-flex flex-row align-items-center justify-content-center col-12 h-100 bg-light-gray-3 ${styles.profileImg}  `}
                >
                  <FontAwesomeIcon
                    icon={faUser as IconProp}
                    className={`color-white  `}
                    style={{ fontSize: "60px", zIndex: 1 }}
                  />
                </div>
              )}
              {/* `<img
                alt="userImage"
                src={userContact.imageurl}
                className={`${styles.profileImg}  `}
              />` */}
            </span>
            <span className="light-title fw-bold ps-3 col-10">
              {userContact.name}, you are a real networker!
            </span>
          </div>
          <div
            className={`d-flex flex-row align-items-center justify-content-end col-4  ${styles.shareBtnContainer}`}
          >
            {/* <div
              className={`${styles.shareBtn} col-xl-8 col-11 d-flex flex-row align-items-center justify-content-center sub-title`}
            >
              <img src={share} className={`${styles.shareImg} me-3`} />
              Share your data
            </div> */}
          </div>
        </div>
        <div
          className={`d-flex flex-row align-items-center flex-wrap justify-content-around col-xl-11 col-12  mt-4 ${styles.dataRow}`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-start col-3  ${styles.smallCont}`}
          >
            <span className="col-12 d-flex flex-row align-items-center text-xxl h-50">
              Tap on profile
            </span>
            <span className="col-12 d-flex flex-row align-items-center text-xxl fw-bold mt-3">
              {userCounter.global_click}
            </span>
          </div>
          <div
            className={`d-flex flex-column align-items-center justify-content-start col-4 ${styles.smallCont}`}
          >
            <span className="col-12 d-flex flex-row align-items-center text-xxl h-50">
              Exchange Number
            </span>
            <span className="col-12 d-flex flex-row align-items-center text-xxl fw-bold mt-3">
              {userCounter.exchange_number}
            </span>
          </div>
          <div
            className={`d-flex flex-column align-items-center justify-content-start col-4  ${styles.smallCont}`}
          >
            <span className="col-12 d-flex flex-row align-items-center text-xxl h-50">
              Tap on 'Save Contact'
            </span>
            <span className="col-12 d-flex flex-row align-items-center text-xxl fw-bold mt-3">
              {userCounter.add_contact_click}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex flex-xl-row flex-column col-12  mt-5 mb-10 mb-xl-0">
        <div className="d-flex flex-column align-items-start justify-content-center col-xl-9 col-12  ">
          <div className={`d-flex flex-column col-12  ${styles.savedCardDiv} `}>
            <div
              className={`d-flex flex-row col-12 align-items-center justify-content-start light-title fw-bold ${styles.titleRow}`}
            >
              <img alt="foglie" src={foglie} />
              <span className="ms-2">
                You are a sustainable leader, you now saved:
              </span>
            </div>
            <div className="d-flex flex-row col-12 align-items-center justify-content-start">
              <div
                className={`d-flex flex-column col-4  mt-4 ${styles.cardCounter}`}
              >
                <span className="text-xxl"> Paper Card</span>
                <span className="text-xxl fw-bold mt-4"> {paperCardSaved}</span>
              </div>
              <div className="d-flex flex-row col-7  text-xxl ms-4">
                *Number of paper business cards saved by sharing your profile
                digitally or collecting a digital contact.
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-column align-items-start justify-content-start col-12  mt-5 ${styles.savedMaterialsDiv} `}
          >
            <div
              className={`d-flex flex-row col-12 align-items-center justify-content-start ${styles.titleRow}`}
            >
              <span className="light-title fw-bold">
                ... which means you saved:
              </span>
            </div>
            <div className="d-flex flex-row col-11 flex-wrap align-items-start justify-content-around mt-5 ">
              <div className={`${styles.materialSavedDiv} `}>
                <span className="text-xxl d-flex flex-row align-items-center justify-content-start">
                  <img alt="woodIcon" src={wood} className="me-2" /> Wood
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-start mt-4 text-xxl fw-bold">
                  {woodSaved} Kg
                </span>
              </div>
              <div className={`${styles.materialSavedDiv}`}>
                <span className="text-xxl d-flex flex-row align-items-center justify-content-start">
                  <img alt="waterIcon" src={water} className="me-2" /> Water
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-start mt-4 text-xxl fw-bold">
                  {waterSaved} Liters
                </span>
              </div>
              <div className={`${styles.materialSavedDiv} mt-xl-0 `}>
                <span className="text-xxl d-flex flex-row align-items-center justify-content-start">
                  <img alt="energyIcon" src={energy} className="me-2" /> Energy
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-start mt-4 text-xxl fw-bold">
                  {enegrySaved} Kwh
                </span>
              </div>
              <div className={`${styles.materialSavedDiv} mt-xl-0 `}>
                <span className="text-xxl d-flex flex-row align-items-center justify-content-start col-12">
                  <img alt="co2Icon" src={co2} className="me-2" />
                  CO<sub>2</sub>
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-start mt-4 text-xxl fw-bold">
                  {co2Saved} Kg
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-xl-3 col-12   d-flex flex-row align-items-start justify-content-end `}
        >
          <div
            className={`d-flex flex-column h-100 col-12 mt-xl-0 mt-5 ${styles.co2Div}`}
          >
            <div
              className="d-flex flex-row col-11 light-title fw-bold color-dark_grey_50"
              style={{ zIndex: 1 }}
            >
              With the purchase of the card you have planted a tree, which will
              offset:
            </div>
            <div
              className="d-flex flex-row align-items-center justify-content-start col-12 light-title fw-bold color-white mt-5"
              style={{ zIndex: 1 }}
            >
              50 kg of CO<sub>2</sub>
            </div>
            <div className={`${styles.thankYou} light-title fw-bold`}>
              Thank you!
            </div>
            <img alt="treeIcon" src={tree} className={`${styles.treeImg}`} />
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Analytics;
