import React, { useEffect, useState } from "react";

import BusinessPlatformStore, {
  addCompanyContacts,
  addUserExchanges,
  setCompanyContacts,
  setCompanyContactsPageNumber,
  setCurrentMenu,
  setExchangePageNumber,
} from "../../store/BusinessPlatformStore";

import styles from "./CompanyContactsList.module.scss";
import { useStoreState } from "pullstate";
import {
  getCompanyContacts,
  getCompanyContactsPageNumber,
  getExchangePageNumber,
  getPlatformUser,
  getUserCounter,
  getUserExchanges,
} from "../../store/Selector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ExchangeRowBig from "../../components/ExchangeRowBig/ExchangeRowBig";
import axiosHelper from "../../helpers/axiosHelper";
import ContactRowBig from "../../components/ContactRowBig/ContactRowBig";
import axios from "axios";

const CompanyContactsList: React.FC = () => {
  const companyContacts = useStoreState(
    BusinessPlatformStore,
    getCompanyContacts
  );
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);
  const exchangePageCharged = useStoreState(
    BusinessPlatformStore,
    getCompanyContactsPageNumber
  );

  const [page, setPage] = useState(1);
  const [searchMethod, setSearchMethod] = useState("surname");
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState<CompanyContact[]>([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = Math.min(
      startIndex + itemsPerPage,
      companyContacts.length
    );

    setCurrentItems(companyContacts.slice(startIndex, endIndex));
  }, [page, companyContacts]);

  const getNextPage = async () => {
    if (page > exchangePageCharged) {
      await axiosHelper.getCompanyContacts(page).then((response) => {
        if (response.contacts.length > 0) {
          setCompanyContactsPageNumber(page);
          addCompanyContacts(response.contacts);
          setPage((prevPage) => Math.min(prevPage + 1));
        }
      });
    }
    if (companyContacts.length / 10 > page) {
      setPage((prevPage) => Math.min(prevPage + 1));
    }
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    setCurrentMenu("Contact List");
    getList();
  }, []);

  const searchContact = async () => {
    if (searchString !== "") {
      if (searchMethod === "surname") {
        await axiosHelper
          .searchContactBySurname(searchString)
          .then((response) => {
            setCompanyContactsPageNumber(1);
            setCompanyContacts(response.contacts);
            setPage(1);
            setSearchString("");
          });
      } else {
        await axiosHelper.searchContactByUuid(searchString).then((response) => {
          setCompanyContactsPageNumber(1);
          setCompanyContacts(response.contacts);
          setPage(1);
          setSearchString("");
        });
      }
    } else {
      await axiosHelper.getCompanyContacts(0).then((response) => {
        setCompanyContactsPageNumber(1);
        setCompanyContacts(response.contacts);
        setPage(1);
      });
    }
  };

  const getList = async () => {
    await axiosHelper
      .getCompanyContacts(0)
      .then((response) => setCompanyContacts(response.contacts));
  };

  return (
    <>
      {/* <Header title="Exchanges List" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12 bg-white p-xl-5 p-3 overflow-hidden"> */}
      <div className="col-12 d-flex flex-row align-items-center justify-content-start">
        <span className="col-4 col-xl-3 text fw-bold">Search Contact </span>
        <div className="col-8 col-xl-5 d-flex flex-row  align-items-center justify-content-around">
          <span
            className={`col-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${
              styles.btnSearchMethod
            } ${searchMethod === "surname" && styles.btnActive}`}
            onClick={() => setSearchMethod("surname")}
          >
            BY SURNAME
          </span>
          <span
            className={`col-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${
              styles.btnSearchMethod
            } ${searchMethod === "uuid" && styles.btnActive}`}
            onClick={() => setSearchMethod("uuid")}
          >
            BY UUID
          </span>
        </div>
      </div>
      <div className="col-12 d-flex flex-row align-items-center justify-content-start mt-4">
        <input
          type="text"
          className="col-5 text"
          placeholder={`Search contact ...`}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <span
          className={`col-2 ms-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${styles.btnSearch} `}
          onClick={() => searchContact()}
        >
          Search
        </span>
      </div>
      <div
        className="col-12 d-flex flex-row mt-5 pb-3 "
        style={{ borderBottom: "1px solid black" }}
      >
        <div
          className={`col-xl-3 col-4  text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          ID
        </div>
        <div
          className={`col-xl-3  col-3 text-xxl d-flex  flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Name
        </div>
        <div
          className={`col-xl-2 col-4 text-xxl d-xl-flex d-none flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Role
        </div>
        <div
          className={`col-xl-3   text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
        >
          Email
        </div>
      </div>
      <div
        className="d-flex flex-column col-12 align-items-start justify-content-start "
        style={{ minHeight: "55vh" }}
      >
        {currentItems.length > 0 &&
          currentItems.map((item, index) => (
            <ContactRowBig
              key={index}
              name={item.name}
              surname={item.surname}
              email={item.email}
              role={item.role}
              uuid={item.uuid}
            />
          ))}
      </div>

      <div
        className={`d-flex  flex-row align-items-center justify-content-end  col-12 color-black text-xxl ${styles.pager}`}
      >
        <FontAwesomeIcon
          icon={faAngleLeft as IconProp}
          className="pe-3 "
          style={{ fontSize: "1.8rem" }}
          onClick={() => getPrevPage()}
        />
        <span className="fw-bold me-2">Pag {page}</span>
        {/* <span className="color-dark_grey_50 ms-2 fw-bold">{maxPage}</span> */}
        <FontAwesomeIcon
          icon={faAngleRight as IconProp}
          className="ps-3 "
          style={{ fontSize: "1.8rem" }}
          onClick={() => getNextPage()}
        />
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default CompanyContactsList;
