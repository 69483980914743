import React, { useCallback, useEffect, useState } from "react";

import BusinessPlatformStore, {
  setCompany,
  setCompanyContacts,
  setCurrentMenu,
  updateCompanyLogo,
} from "../../store/BusinessPlatformStore";

import styles from "./EditCompany.module.scss";
import { useStoreState } from "pullstate";
import { getCompany, getCompanyContacts } from "../../store/Selector";

import edit from "../../assets/editGreen.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Dropzone from "react-dropzone";
import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate } from "react-router-dom";

const EditCompany: React.FC = () => {
  const [logo, setLogo] = useState<File>();
  const redirect = useNavigate();

  const company = useStoreState(BusinessPlatformStore, getCompany);
  const contactList = useStoreState(BusinessPlatformStore, getCompanyContacts);

  const [loadingLogo, setLoadingLogo] = useState(false);
  const [website, setWebsite] = useState(company.website);
  const [address, setAddress] = useState(company.address);
  const [email, setEmail] = useState(company.email);
  const [name, setName] = useState(company.name);

  const [message, setMessage] = useState("");

  const onDropLogo = async (e: any) => {
    // console.log(e?.[0]);
    setLogo(e?.[0]);
  };

  const uploadLogo = useCallback(async () => {
    if (logo) {
      let uploadLogo: uploadImageData = { image: logo };
      setLoadingLogo(true);
      await axiosHelper.addCompanyLogo(uploadLogo).then((response) => {
        // console.log(response);
        updateCompanyLogo(response);
        setLogo(undefined);
        setLoadingLogo(false);
      });
    }
  }, [logo]);

  useEffect(() => {
    if (logo) {
      uploadLogo();
    }
  }, [logo, uploadLogo]);

  const removeLogo = async () => {
    await axiosHelper
      .removeCompanyLogo()
      .then((response) => {
        // console.log(response);
        updateCompanyLogo("");
      })
      .catch((error) => {});
  };

  const goToCompanyContactList = async () => {
    if (!contactList.length) {
      await axiosHelper.getCompanyContacts(0).then((response) => {
        // console.log(response.contacts);
        setCompanyContacts(response.contacts);
      });
    }
    redirect("/contact-list");
  };

  const updateCompanyInformations = async () => {
    const data: updateCompanyData = {
      name: name,
      address: address,
      email: email,
      website: website,
    };
    // console.log(data);
    await axiosHelper
      .updateCompanyInfo(data)
      .then((response) => {
        // console.log(response);
        setCompany(response.companyUpdated);
        showMessage();
      })
      .catch((error) => console.log(error));
  };

  const showMessage = () => {
    setMessage("Informations have been succesfully updated!");
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  useEffect(() => {
    setCurrentMenu("Edit Company");
  }, []);

  return (
    <>
      {/* <Header title="Edit Profile" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12  bg-white p-xl-5 p-3 d-flex flex-row align-items-start justify-content-center overflow-hidden"> */}
      <div className="d-flex flex-row align-items-start justify-content-center overflow-hidden col-12">
        <div className="d-flex flex-column align-items-center justify-content-start col-xl-8 col-12 ">
          <div className="d-flex flex-row col-12  align-items-center justify-content-center mt-5">
            <span
              className={`${styles.updateContactsBtn} d-flex flex-row align-items-center justify-content-around col-md-4 col-7`}
              onClick={() => goToCompanyContactList()}
            >
              Contacts lists
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                className="ps-4"
              />
            </span>
          </div>
          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5`}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">Company Logo | Personal Logo</span>
              {/* <span className={`${styles.editBtn} fw-bold sub-title`}>
                  <img src={edit} className="me-2" /> Edit
                </span> */}
            </div>
            <div
              className={`d-flex flex-row  col-12 align-items-center justify-content-center  mt-4 ${styles.logoContainer}`}
            >
              {company.logourl ? (
                <img alt="logoImage" src={company.logourl} />
              ) : (
                <>
                  {!loadingLogo ? (
                    <Dropzone
                      onDrop={onDropLogo}
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                        "image/jpg": [],
                        // "application/pdf": [],
                      }}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className={`${styles.inputLogoContainer} h-100 col-12 d-flex align-items-center justify-content-center color-black text-center p-4`}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="text-s">
                            Drag and drop an image or
                            <span
                              className="ms-1 color-dark_grey_50 cursor-pointer"
                              style={{ textDecoration: "underline" }}
                            >
                              choose a file
                            </span>
                            <br />
                            (.jpg/.png/.jpeg)
                          </span>
                        </div>
                      )}
                    </Dropzone>
                  ) : (
                    <div className={`${styles.loading} `}> </div>
                  )}
                </>
              )}
              {company.logourl && (
                <div
                  className={`${styles.removeLogo} cursor-pointer`}
                  onClick={() => removeLogo()}
                >
                  <FontAwesomeIcon
                    icon={faXmark as IconProp}
                    // style={{ fontSize: "25px" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5 color-black`}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="text-xxl fw-bold">Company Informations</span>
            </div>
            <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-5">
              <span className="sub-title">Name </span>
              <input
                type="text"
                className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
              <span className="sub-title">Website </span>
              <input
                type="text"
                className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
              <span className="sub-title">Address </span>
              <input
                type="text"
                className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column col-12 align-items-start justify-content-start mt-4">
              <span className="sub-title">Email </span>
              <input
                type="text"
                className={`text-xxl mt-3 col-12 ${styles.inputField} ps-5`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className={`d-flex flex-row align-items-center justify-content-end col-12 mt-4`}
            >
              <span
                className={`${styles.editBtn} cursor-pointer text px-5`}
                onClick={() => updateCompanyInformations()}
              >
                Update
                <img src={edit} className="ms-2" />
              </span>
            </div>
          </div>
          {message && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12  mt-3 text-s">
              {message}
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default EditCompany;
