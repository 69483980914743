import React, { useCallback, useEffect, useState } from "react";

import BusinessPlatformStore, {
  setCurrentMenu,
  setPlatformContact,
  updateUserImage,
  updateUserLogo,
} from "../../store/BusinessPlatformStore";

import styles from "./EditProfile.module.scss";
import { useStoreState } from "pullstate";
import { getPlatformContact, getPlatformUser } from "../../store/Selector";

import edit from "../../assets/editGreen.svg";
import xIcon from "../../assets/X_ICON.png";
import youtubeIcon from "../../assets/youtubeBlack.svg";

import userImg from "../../assets/user.svg";

import phone from "../../assets/phone.svg";
import valigetta from "../../assets/valigetta.svg";
import instagram from "../../assets/instagramBlack.svg";
import facebook from "../../assets/facebookBlack.svg";
import linkedin from "../../assets/linkedinBlack.svg";
import light from "../../assets/dlc_original_white.png";
import dark from "../../assets/dlc_original_black.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import FileComponent from "../../components/FileComponent/FileComponent";
import Dropzone from "react-dropzone";
import axiosHelper from "../../helpers/axiosHelper";
import AboutMePopUp from "../../components/AboutMePopUp/AboutMePopUp";
import AboutWorkPopUp from "../../components/AboutWorkPopUp/AboutWorkPopUp";
import SocialsPopUp from "../../components/SocialsPopUp/SocialsPopUp";
import { faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import LinksPopUp from "../../components/LinksPopUp/LinksPopUp";

const EditProfile: React.FC = () => {
  const userContact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);

  const [image, setImage] = useState<File>();
  const [logo, setLogo] = useState<File>();
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [aboutMePopUp, setAboutMePopUp] = useState(false);
  const [aboutWorkPopUp, setAboutWorkPopUp] = useState(false);
  const [socialsPopUp, setSocialsPopUp] = useState(false);
  const [linksPopUp, setLinksPopUp] = useState(false);

  const onDropImage = async (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  const uploadImage = useCallback(async () => {
    if (image) {
      let uploadImage: uploadImageData = { image: image };
      setLoadingImage(true);
      if (user.user_type !== "G") {
        await axiosHelper.addUserImage(uploadImage).then((response) => {
          console.log(response);
          updateUserImage(response);
          setImage(undefined);
          setLoadingImage(false);
        });
      } else {
        await axiosHelper
          .addUserImageGod(uploadImage, userContact.uuid)
          .then((response) => {
            console.log(response);
            updateUserImage(response);
            setImage(undefined);
            setLoadingImage(false);
          });
      }
    }
  }, [image]);

  useEffect(() => {
    if (image) {
      uploadImage();
    }
  }, [image, uploadImage]);

  const removeImage = async () => {
    if (user.user_type !== "G") {
      await axiosHelper
        .removeUserImage()
        .then((response) => {
          console.log(response);
          updateUserImage("");
        })
        .catch((error) => console.log(error));
    } else {
      await axiosHelper
        .removeUserImageGod(userContact.uuid)
        .then((response) => {
          console.log(response);
          updateUserImage("");
        })
        .catch((error) => console.log(error));
    }
  };

  const onDropLogo = async (e: any) => {
    console.log(e?.[0]);
    setLogo(e?.[0]);
  };

  const uploadLogo = useCallback(async () => {
    if (logo) {
      let uploadLogo: uploadImageData = { image: logo };
      setLoadingLogo(true);
      await axiosHelper.addUserLogo(uploadLogo).then((response) => {
        console.log(response);
        updateUserLogo(response);
        setLogo(undefined);
        setLoadingLogo(false);
      });
    }
  }, [logo]);

  useEffect(() => {
    if (logo) {
      uploadLogo();
    }
  }, [logo, uploadLogo]);

  const removeLogo = async () => {
    await axiosHelper
      .removeUserLogo()
      .then((response) => {
        console.log(response);
        updateUserLogo("");
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setCurrentMenu("Edit Profile");
  }, []);

  const changeMode = async (mode: string) => {
    console.log("change mode");
    if (userContact.mode !== mode) {
      const data: ChangeModeData = {
        mode: mode,
      };
      if (user.user_type !== "G") {
        await axiosHelper
          .changeMode(data)
          .then((response) => {
            console.log(response);
            setPlatformContact(response.updated);
          })
          .catch((error) => console.log(error));
      } else {
        await axiosHelper
          .changeModeGod(data, userContact.uuid)
          .then((response) => {
            console.log(response);
            setPlatformContact(response.updated);
          })
          .catch((error) => console.log(error));
      }
    }
  };
  return (
    <>
      {/* <Header title="Edit Profile" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12  bg-white p-xl-5 p-3 d-flex flex-row align-items-start justify-content-center overflow-hidden"> */}
      <div className="d-flex flex-row align-items-start justify-content-center overflow-hidden col-12">
        <div className="d-flex flex-column align-items-center justify-content-start col-xl-8 col-12 ">
          <div
            className={`col-6 d-flex flex-row align-items-center justify-content-center ${styles.editImageContainer}`}
          >
            <div
              className={`col-12  d-flex flex-row align-items-center justify-content-center ${styles.imageContainer}`}
            >
              {userContact.imageurl ? (
                <img alt="userImage" src={userContact.imageurl} />
              ) : (
                <div
                  className={`${styles.imageContainerEmpty} d-flex flex-row align-items-center justify-content-center`}
                >
                  {!userContact.imageurl && !loadingImage && (
                    <Dropzone
                      onDrop={onDropImage}
                      accept={{
                        "image/jpeg": [],
                        "image/png": [],
                        "image/jpg": [],
                        // "application/pdf": [],
                      }}
                      maxSize={31457280}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className={`${styles.inputImageContainer} h-100 col-12 d-flex align-items-center justify-content-center color-black text-center p-4`}
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="text-s">
                            Drag and drop an image or
                            <span
                              className="ms-1 color-dark_grey_50 cursor-pointer"
                              style={{ textDecoration: "underline" }}
                            >
                              choose a file
                            </span>
                            <br />
                            (.jpg/.png/.jpeg)
                          </span>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {loadingImage && (
                    <div className={`${styles.loading} `}> </div>
                  )}
                  {/* <img src={avatar} /> */}
                </div>
              )}
            </div>

            {userContact.imageurl && (
              <div
                className={`${styles.removeImg} cursor-pointer `}
                onClick={() => removeImage()}
              >
                <FontAwesomeIcon
                  icon={faXmark as IconProp}
                  // style={{ fontSize: "20px" }}
                />
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center justify-content-start mt-5 col-12 ">
            <span className="light-title fw-bold">
              {userContact.name} {userContact.surname}
            </span>
            <span className="text-xxl mt-3">{userContact.email}</span>
          </div>
          {!userContact.company_uuid && (
            <div
              className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5`}
            >
              <div className="d-flex flex-row col-12 align-items-center justify-content-between">
                <span className="sub-title">Company Logo | Personal Logo</span>
                {/* <span className={`${styles.editBtn} fw-bold sub-title`}>
                  <img src={edit} className="me-2" /> Edit
                </span> */}
              </div>
              <div
                className={`d-flex flex-row  col-12 align-items-center justify-content-center  mt-4 ${styles.logoContainer}`}
              >
                {userContact.logourl ? (
                  <img alt="logoImage" src={userContact.logourl} />
                ) : (
                  <>
                    {!loadingLogo ? (
                      <Dropzone
                        onDrop={onDropLogo}
                        accept={{
                          "image/jpeg": [],
                          "image/png": [],
                          "image/jpg": [],
                          // "application/pdf": [],
                        }}
                        maxSize={31457280}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className={`${styles.inputLogoContainer} h-100 col-12 d-flex align-items-center justify-content-center color-black text-center p-4`}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <span className="text-s">
                              Drag and drop an image or
                              <span
                                className="ms-1 color-dark_grey_50 cursor-pointer"
                                style={{ textDecoration: "underline" }}
                              >
                                choose a file
                              </span>
                              <br />
                              (.jpg/.png/.jpeg)
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    ) : (
                      <div className={`${styles.loading} `}> </div>
                    )}
                  </>
                )}
                {userContact.logourl && (
                  <div
                    className={`${styles.removeLogo} cursor-pointer`}
                    onClick={() => removeLogo()}
                  >
                    <FontAwesomeIcon
                      icon={faXmark as IconProp}
                      // style={{ fontSize: "25px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5`}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">About me</span>
              <span
                className={`${styles.editBtn} fw-bold sub-title cursor-pointer`}
                onClick={() => setAboutMePopUp(!aboutMePopUp)}
              >
                <img alt="editIcon" src={edit} className="me-2" /> Edit
              </span>
            </div>
            <AboutMePopUp
              firstName={userContact.name}
              lastName={userContact.surname}
              phonenumber={userContact.phone}
              email={userContact.email}
              prefix={userContact.prefix}
              open={aboutMePopUp}
              close={() => setAboutMePopUp(!aboutMePopUp)}
            />
            <div
              className={`d-flex flex-column col-9  mt-4  align-items-start justify-content-start`}
            >
              <div className="d-flex flex-row col-12 align-items-start justify-content-start">
                <div className="col-2 d-flex flex-row align-items-center justify-content-start">
                  <img
                    alt="userIcon"
                    src={userImg}
                    style={{ height: "25px" }}
                  />
                </div>
                <div className="col-10  text-xxl  d-flex flex-column align-items-start justify-content-start">
                  <span>
                    {userContact.name} {userContact.surname}
                  </span>
                  <span className="mt-2">{userContact.email}</span>
                  {/* <span className="mt-2">{userContact.phone}</span> */}
                </div>
              </div>
              <div className="d-flex flex-row col-12 align-items-center justify-content-start mt-4">
                <div className="col-2 d-flex flex-row align-items-center justify-content-start">
                  <img alt="phoneIcon" src={phone} style={{ height: "25px" }} />
                </div>
                <div className="col-10 text-xxl  d-flex flex-column align-items-start justify-content-start">
                  <span>
                    {userContact.prefix} {userContact.phone}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5`}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">About work</span>
              <span
                className={`${styles.editBtn} fw-bold sub-title cursor-pointer`}
                onClick={() => setAboutWorkPopUp(!aboutWorkPopUp)}
              >
                <img alt="editIcon" src={edit} className="me-2" /> Edit
              </span>
            </div>
            <div
              className={`d-flex flex-column col-9  mt-4  align-items-start justify-content-start`}
            >
              <div className="d-flex flex-row col-12 align-items-start justify-content-start">
                <div className="col-2 d-flex flex-row align-items-center justify-content-start">
                  <img
                    alt="workIcon"
                    src={valigetta}
                    style={{ height: "25px" }}
                  />
                </div>
                <div className="col-10  text-xxl  d-flex flex-column align-items-start justify-content-start">
                  <span>{userContact.role}</span>
                  <span className="mt-2">{userContact.company}</span>
                  <span className="mt-2">{userContact.workmail}</span>
                  <span className="mt-2">
                    {userContact.workprefix} {userContact.workphone}
                  </span>
                  <span
                    className="mt-2 cursor-pointer"
                    style={{ textDecoration: "underline" }}
                    onClick={() => window.open(userContact.website, "_blank")}
                  >
                    {userContact.website}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <AboutWorkPopUp
            role={userContact.role}
            company={userContact.company}
            workmail={userContact.workmail}
            website={userContact.website}
            open={aboutWorkPopUp}
            close={() => setAboutWorkPopUp(!aboutWorkPopUp)}
            workphone={userContact.workphone}
            workprefix={userContact.workprefix}
          />

          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5`}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">Social </span>
              <span
                className={`${styles.editBtn} fw-bold sub-title cursor-pointer`}
                onClick={() => setSocialsPopUp(!socialsPopUp)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add
              </span>
            </div>
            <div
              className={`d-flex flex-column col-xl-9 col-12  mt-4  align-items-start justify-content-start`}
            >
              <div className="d-flex flex-row col-12 align-items-start justify-content-start flex-wrap">
                {userContact.instagram !== "" &&
                  userContact.instagram !== null && (
                    <div
                      className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer ${styles.socialRow}`}
                      onClick={() =>
                        window.open(userContact.instagram, "_blank")
                      }
                    >
                      <img
                        alt="instagramIcon"
                        src={instagram}
                        className="me-3"
                      />
                      Instagram
                    </div>
                  )}
                {userContact.facebook !== "" &&
                  userContact.facebook !== null && (
                    <div
                      className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer ${styles.socialRow}`}
                      onClick={() =>
                        window.open(userContact.facebook, "_blank")
                      }
                    >
                      <img alt="facebookIcon" src={facebook} className="me-3" />
                      Facebook
                    </div>
                  )}
                {userContact.linkedin !== "" &&
                  userContact.linkedin !== null && (
                    <div
                      className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer  ${styles.socialRow}`}
                      onClick={() =>
                        window.open(userContact.linkedin, "_blank")
                      }
                    >
                      <img alt="linkedinIcon" src={linkedin} className="me-3" />
                      LinkedIn
                    </div>
                  )}
                {userContact.whatsapp === 1 &&
                  userContact.prefix !== null &&
                  userContact.phone !== null && (
                    <div
                      className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer  ${styles.socialRow}`}
                      onClick={() =>
                        window.open(
                          `https://wa.me/${
                            userContact.prefix.substring(
                              1,
                              userContact.prefix.length
                            ) + userContact.phone
                          }`,
                          "_blank"
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        style={{ fontSize: "25px" }}
                        className="me-3"
                      />
                      WhatsApp
                    </div>
                  )}

                {userContact.twitter !== "" && userContact.twitter !== null && (
                  <div
                    className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer ${styles.socialRow}`}
                    onClick={() => window.open(userContact.twitter, "_blank")}
                  >
                    <img alt="xIcon" src={xIcon} className="me-3" />X
                  </div>
                )}
                {userContact.youtube !== "" && userContact.youtube !== null && (
                  <div
                    className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer ${styles.socialRow}`}
                    onClick={() => window.open(userContact.youtube, "_blank")}
                  >
                    <img alt="youtubeIcon" src={youtubeIcon} className="me-3" />
                    YouTube
                  </div>
                )}
                {userContact.tiktok !== "" && userContact.tiktok !== null && (
                  <div
                    className={`col-6  d-flex flex-row align-items-center justify-content-start cursor-pointer ${styles.socialRow}`}
                    onClick={() => window.open(userContact.tiktok, "_blank")}
                  >
                    <FontAwesomeIcon
                      icon={faTiktok as IconProp}
                      className="me-3"
                      style={{ fontSize: "25px" }}
                    />
                    TikTok
                  </div>
                )}
              </div>
            </div>
          </div>
          <SocialsPopUp
            instagram={userContact.instagram}
            facebook={userContact.facebook}
            whatsapp={userContact.whatsapp}
            linkedin={userContact.linkedin}
            x={userContact.twitter}
            youtube={userContact.youtube}
            tiktok={userContact.tiktok}
            open={socialsPopUp}
            close={() => setSocialsPopUp(!socialsPopUp)}
          />
          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5  `}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">My links </span>
              <span
                className={`${styles.editBtn} fw-bold sub-title cursor-pointer`}
                onClick={() => setLinksPopUp(!linksPopUp)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add
              </span>
            </div>
            <div
              className={`d-flex flex-column col-9  mt-4  align-items-start justify-content-start`}
            >
              <div className="d-flex flex-column col-12 align-items-start justify-content-start flex-wrap">
                <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                  {userContact.link1 !== null &&
                    userContact.label_link1 !== null && (
                      <div className="d-flex flex-row col-12 align-items-center justify-content-start">
                        <span
                          className="text-xxl cursor-pointer"
                          style={{ textDecoration: "underline" }}
                          onClick={() =>
                            userContact.link1 !== null &&
                            window.open(userContact.link1, "_blank")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faLink as IconProp}
                            className="me-3"
                            style={{ fontSize: "20px" }}
                          />
                          {userContact.label_link1}
                        </span>
                      </div>
                    )}
                  {userContact.link2 !== null &&
                    userContact.label_link2 !== null && (
                      <div className="d-flex flex-row col-12 align-items-center justify-content-start">
                        <span
                          className="text-xxl cursor-pointer"
                          style={{ textDecoration: "underline" }}
                          onClick={() =>
                            userContact.link2 !== null &&
                            window.open(userContact.link2, "_blank")
                          }
                        >
                          <FontAwesomeIcon
                            icon={faLink as IconProp}
                            className="me-3"
                            style={{ fontSize: "20px" }}
                          />
                          {userContact.label_link2}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <LinksPopUp
            open={linksPopUp}
            close={() => setLinksPopUp(!linksPopUp)}
            label1={userContact.label_link1}
            link1={userContact.link1}
            label2={userContact.label_link2}
            link2={userContact.link2}
          />

          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5  `}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">My Files </span>
              {/* <span className={`${styles.editBtn} fw-bold sub-title`}>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add
              </span> */}
            </div>
            <div
              className={`d-flex flex-column col-12  mt-4  align-items-start justify-content-around flex-wrap`}
            >
              <FileComponent
                url={userContact.file1url}
                filenumber={1}
                filename={userContact.file1label}
              />
              <FileComponent
                url={userContact.file2url}
                filenumber={2}
                filename={userContact.file2label}
              />
              <FileComponent
                url={userContact.file3url}
                filenumber={3}
                filename={userContact.file3label}
              />
            </div>
          </div>
          <div
            className={`d-flex flex-column ${styles.roundedDiv} col-xl-9 col-12 mt-5 mb-10 mb-xl-5 `}
          >
            <div className="d-flex flex-row col-12 align-items-center justify-content-between">
              <span className="sub-title">Web Interface </span>
              {/* <span className={`${styles.editBtn} fw-bold sub-title`}>
                <FontAwesomeIcon icon={faPlus} className="me-2" /> Add
              </span> */}
            </div>
            <div
              className={`d-flex flex-row col-12  mt-4  align-items-center justify-content-between  `}
            >
              <div className="d-flex flex-column align-items-center justify-content-start col-md-5 col-6 ">
                <div className={`${styles.webinterfaceImageContainer}`}>
                  <img alt="webLight" src={light} />
                </div>
                <div className="d-flex flex-row col-12  align-items-center justify-content-center mt-3">
                  <div
                    className={`${
                      userContact.mode === "LIGHT"
                        ? styles.webInterfaceBtnSelected
                        : styles.webInterfaceBtn
                    } col-6 text-xxl cursor-pointer`}
                    onClick={() => changeMode("LIGHT")}
                  >
                    Light
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-md-5 col-6 ">
                <div className={`${styles.webinterfaceImageContainer}`}>
                  <img alt="webDark" src={dark} />
                </div>
                <div className="d-flex flex-row col-12  align-items-center justify-content-center mt-3">
                  <div
                    className={`${
                      userContact.mode === "NIGHT"
                        ? styles.webInterfaceBtnSelected
                        : styles.webInterfaceBtn
                    } col-6 text-xxl cursor-pointer`}
                    onClick={() => changeMode("NIGHT")}
                  >
                    Dark
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default EditProfile;
