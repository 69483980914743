import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home/Home";
import VerifyEmail from "./routes/VerifyEmail/VerifyEmail";
import Login from "./routes/Login/Login";
import ResetPwd from "./routes/ResetPwd/ResetPwd";
import Register from "./routes/Register/Register";
import EditProfile from "./routes/EditProfile/EditProfile";
import Exchange from "./routes/Exchange/Exchange";
import Analytics from "./routes/Analytics/Analytics";
import AccountSettings from "./routes/AccountSettings/AccountSettings";
import { useStoreState } from "pullstate";
import BusinessPlatformStore from "./store/BusinessPlatformStore";
import { getPlatformUser } from "./store/Selector";
import Header from "./components/Header/Header";
import PageSkeleton from "./components/PageSkeleton/PageSkeleton";
import AddUuid from "./routes/AddUuid/AddUuid";
import GoogleErrorPage from "./routes/GoogleErrorPage/GoogleErrorPage";
import FacebookErrorPage from "./routes/FacebookErrorPage/FacebookErrorPage";
import Onboarding from "./routes/Onboarding/Onboarding";
import VerifyUpdateEmail from "./routes/VerifyUpdateEmail/VerifyUpdateEmail";
import StripeSuccess from "./routes/StripeCheckout/StripeSuccess";
import EditCompany from "./routes/EditCompany/EditCompany";
import CompanyContactsList from "./routes/CompanyContactsList/CompanyContactsList";

const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <Header title="Home" />
      <div className="d-flex flex-row col-12">
        <PageSkeleton />
        <div
          className=" col-xl-10  col-12 bg-white p-xl-5 p-3 overflow-hidden"
          style={{ minHeight: "90vh" }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

const Container = () => {
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);

  return (
    <Routes>
      {/* HOME */}
      {user.email ? (
        <Route
          path={`/`}
          element={
            <DefaultLayout>
              <Home />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/`} element={<Login />} />
      )}
      {/* EDIT PROFILE */}
      {user.email ? (
        <Route
          path={`/edit-profile`}
          element={
            <DefaultLayout>
              <EditProfile />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/edit-profile`} element={<Login />} />
      )}

      {user.email ? (
        <Route
          path={`/edit-company`}
          element={
            <DefaultLayout>
              <EditCompany />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/edit-company`} element={<Login />} />
      )}

      {user.email ? (
        user.user_type === "G" ? (
          <Route
            path={`/contact-list`}
            element={
              <DefaultLayout>
                <CompanyContactsList />
              </DefaultLayout>
            }
          />
        ) : (
          <Route path={`/contact-list`} element={<Home />} />
        )
      ) : (
        <Route path={`/contact-list`} element={<Login />} />
      )}

      {/* EXCHANGE */}
      {user.email ? (
        <Route
          path={`/exchange-list`}
          element={
            <DefaultLayout>
              <Exchange />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/exchange-list`} element={<Login />} />
      )}

      {/* ANALYTICS */}
      {user.email ? (
        <Route
          path={`/analytics`}
          element={
            <DefaultLayout>
              <Analytics />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/analytics`} element={<Login />} />
      )}

      {/* ACCOUNT SETTINGS */}
      {user.email ? (
        <Route
          path={`/user-info`}
          element={
            <DefaultLayout>
              <AccountSettings />
            </DefaultLayout>
          }
        />
      ) : (
        <Route path={`/user-info`} element={<Login />} />
      )}

      <Route path={`/success-payment/:sessionId`} element={<StripeSuccess />} />

      {/* <Route path={`/:uuid`} element={<Home />} /> */}
      <Route path={`/auth/verify-email/:token`} element={<VerifyEmail />} />
      <Route
        path={`/auth/verify-update-email/:token`}
        element={<VerifyUpdateEmail />}
      />
      <Route
        path={`/auth/passwordreset/:token/:email`}
        element={<ResetPwd />}
      />
      <Route path={`/login`} element={<Login />} />
      <Route path={`/login/google-error`} element={<GoogleErrorPage />} />
      <Route path={`/login/facebook-error`} element={<FacebookErrorPage />} />
      <Route path={`/register/:uuid`} element={<Register />} />
      <Route path={`/register`} element={<Register />} />
      <Route path={`/addUuid`} element={<AddUuid />} />
      <Route path={`/onboarding`} element={<Onboarding />} />
    </Routes>
  );
};
export default Container;
