import React, { useEffect, useState } from "react";

import BusinessPlatformStore, {
  setCurrentMenu,
} from "../../store/BusinessPlatformStore";

import styles from "./AcountSettings.module.scss";
import { useStoreState } from "pullstate";
import { getPlatformUser } from "../../store/Selector";

import mail from "../../assets/mailIcon.svg";
import lucchetto from "../../assets/lucchetto.svg";
import deleteicon from "../../assets/deleteAccountIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowRightFromBracket,
  faPhone,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ChangePasswordPopUp from "../../components/ChangePasswordPopUp/ChangePasswordPopUp";
import ChangeEmailPopUp from "../../components/ChangeEmailPopUp/ChangeEmailPopUp";
import LogoutPopUp from "../../components/LogoutPopUp/LogoutPopUp";
import DeleteAccountPopUp from "../../components/DeleteAccountPopUp/DeleteAccountPopUp";
import StripeCheckout from "../StripeCheckout/StripeCheckout";

const AccountSettings: React.FC = () => {
  const platformUser = useStoreState(BusinessPlatformStore, getPlatformUser);
  const [changePwdPopUp, setChangePwdPopUp] = useState(false);
  const [changeEmailPopUp, setChangeEmailPopUp] = useState(false);
  const [logoutPopUp, setLogoutPopUp] = useState(false);
  const [deleteAccountPopUp, setDeleteAccountPopUp] = useState(false);

  useEffect(() => {
    setCurrentMenu("Account Settings");
  }, []);

  return (
    <>
      {/* <Header title="Account Settings" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12 bg-white p-md-5 p-3 overflow-hidden" style={{minHeight:'90vh'}}> */}
      <div className="d-flex flex-row align-items-start justify-content-center col-12">
        <div className="d-flex flex-column align-items-center justify-content-center col-xl-7 col-10 mt-5">
          <div
            className={`${styles.roundedDiv} col-12`}
            onClick={() => setChangeEmailPopUp(!changeEmailPopUp)}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <img alt="mailIcon" src={mail} />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">Change Login Email</span>
              <span className={`${styles.rowBorded} ms-3 mt-2 text-xxl`}>
                {platformUser.email}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div>
          <ChangeEmailPopUp
            open={changeEmailPopUp}
            close={() => setChangeEmailPopUp(!changeEmailPopUp)}
          />
          <div
            className={`${styles.roundedDiv} col-12 mt-5`}
            onClick={() => setChangePwdPopUp(!changePwdPopUp)}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <img alt="lucchettoIcon" src={lucchetto} />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">Change Password</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div>
          <ChangePasswordPopUp
            open={changePwdPopUp}
            close={() => setChangePwdPopUp(!changePwdPopUp)}
          />
          <div
            className={`${styles.roundedDiv} col-12 mt-5`}
            onClick={() => setDeleteAccountPopUp(!deleteAccountPopUp)}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <img alt="deleteIcon" src={deleteicon} />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">Delete Account </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div>
          <DeleteAccountPopUp
            open={deleteAccountPopUp}
            close={() => setDeleteAccountPopUp(!deleteAccountPopUp)}
          />

          <div
            className={`${styles.roundedDiv} col-12 mt-5`}
            onClick={() => setLogoutPopUp(!logoutPopUp)}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faArrowRightFromBracket as IconProp}
                fontSize={"35px"}
              />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">Logout </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div>
          <LogoutPopUp
            open={logoutPopUp}
            close={() => setLogoutPopUp(!logoutPopUp)}
          />
          {/* <div
            className={`${styles.roundedDiv}  col-12 mt-5`}
            onClick={() => {
              console.log("faq click");
            }}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faQuestion as IconProp}
                fontSize={"35px"}
              />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">F. A. Q. </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div> */}
          <div
            className={`${styles.roundedDiv} d-flex d-xl-none col-12 mt-5`}
            onClick={() => {
              window.location.href =
                "mailto:assistance@dlctech.it?subject=Business%20Card%20Web%20Platform";
            }}
          >
            <div className="col-1  d-flex flex-row align-items-center justify-content-center">
              <FontAwesomeIcon icon={faPhone as IconProp} fontSize={"35px"} />
            </div>
            <div className="col-10 ps-3 d-flex flex-column align-items-start justify-content-center">
              <span className="ms-3 sub-title">Contact us </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center col-1 ">
              <FontAwesomeIcon
                icon={faArrowRight as IconProp}
                style={{ fontSize: "2rem" }}
              />
            </div>
          </div>
          {/* <StripeCheckout /> */}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default AccountSettings;
