import React, { useCallback, useEffect, useState } from "react";
import styles from "../Login/Login.module.scss";

import axiosHelper from "../../helpers/axiosHelper";
import { useNavigate } from "react-router-dom";

const AddUuid: React.FC = () => {
  const redirect = useNavigate();

  useEffect(() => {
    const item = localStorage.getItem("contact_uuid");
    sendUuid(item?.toString());
  }, []);

  useEffect(() => {
    // Rimuove il parametro #_=_
    if (window.location.hash === "#_=_") {
      window.history.replaceState(null, "", window.location.href.split("#")[0]);
    }
  }, []);

  const sendUuid = useCallback(
    async (uuid?: string) => {
      if (uuid) {
        await axiosHelper.sendUuid(uuid).then((response) => {
          localStorage.removeItem("contact_uuid");
          redirect("/");
        });
      } else {
        await axiosHelper.createContact().then((response) => {
          redirect("/");
        });
      }
    },
    [redirect]
  );

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-center col-12 bg-black ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-column col-xl-8 col-10  align-items-center justify-content-start mt-10  ${styles.boxContainer}`}
        >
          one more second
        </div>
      </div>
    </>
  );
};

export default AddUuid;
