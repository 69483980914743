import React, { useState } from "react";

import styles from "./ChangePasswordPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import parse from "html-react-parser";

interface ExchangeRowBigProps {
  open: boolean;
  close: () => void;
}

const ChangePasswordPopUp: React.FC<ExchangeRowBigProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmNew, setConfirmNew] = useState("");
  const [eyeOld, setEyeOld] = useState(false);
  const [eyePwd, setEyePwd] = useState(false);
  const [eyeNew, setEyeNew] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log("submit");

    if (currentPwd !== "" && newPwd !== "" && confirmNew !== "") {
      if (newPwd !== currentPwd) {
        if (newPwd === confirmNew) {
          await axiosHelper
            .updatePassword(currentPwd, newPwd)
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => {
              // console.log(error.response.data.msg);
              setErrorMessage(error.response.data.msg);
            });
        }
      } else {
        setErrorMessage(
          "Current password equals to new password. <br/> Please change your new password "
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => {
                setCurrentPwd("");
                setNewPwd("");
                setConfirmNew("");
                setErrorMessage("");
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content}  col-12 `}>
              <span className="d-flex flex-row col-12 align-items-center justify-content-center light-title fw-bold mt-3">
                Update Your Password
              </span>

              <form
                onSubmit={handleSubmit}
                className="d-flex flex-column align-items-center justify-content-center col-12 "
              >
                <span className="d-flex flex-row col-12 align-items-center justify-content-center sub-title mt-7">
                  Current Password
                </span>
                <span
                  className={`d-flex flex-row col-10 col-xl-6 align-items-center justify-content-center  mt-3 ${styles.inputContainer}`}
                >
                  <input
                    className="col-11  text-xxl text-center"
                    value={currentPwd}
                    type={!eyeOld ? "password" : "text"}
                    placeholder="Insert your current password"
                    onChange={(e) => setCurrentPwd(e.target.value)}
                  />
                  <span
                    className={`d-flex flex-row col-1  align-items-center justify-content-center  mt-3 `}
                  >
                    <FontAwesomeIcon
                      onClick={() => setEyeOld(!eyeOld)}
                      icon={(!eyeOld ? faEye : faEyeSlash) as IconProp}
                    />
                  </span>
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-center sub-title mt-5">
                  New Password
                </span>
                <span
                  className={`d-flex flex-row col-10 col-xl-6 align-items-center justify-content-center  mt-3  ${styles.inputContainer}`}
                >
                  <input
                    className="col-11 text-xxl text-center"
                    value={newPwd}
                    type={!eyePwd ? "password" : "text"}
                    placeholder="Insert the new password"
                    onChange={(e) => setNewPwd(e.target.value)}
                  />
                  <span
                    className={`d-flex flex-row col-1  align-items-center justify-content-center  mt-3 `}
                  >
                    <FontAwesomeIcon
                      onClick={() => setEyePwd(!eyePwd)}
                      icon={(!eyePwd ? faEye : faEyeSlash) as IconProp}
                    />
                  </span>
                </span>
                <span className="d-flex flex-row col-12 align-items-center justify-content-center sub-title mt-5">
                  Confirm New Password
                </span>
                <span
                  className={`d-flex flex-row col-10 col-xl-6 align-items-center justify-content-center  mt-3  ${styles.inputContainer}`}
                >
                  <input
                    className="col-11 text-xxl text-center"
                    value={confirmNew}
                    type={!eyeNew ? "password" : "text"}
                    placeholder="Insert the new password"
                    onChange={(e) => setConfirmNew(e.target.value)}
                  />
                  <span
                    className={`d-flex flex-row col-1  align-items-center justify-content-center  mt-3 `}
                  >
                    <FontAwesomeIcon
                      onClick={() => setEyeNew(!eyeNew)}
                      icon={(!eyeNew ? faEye : faEyeSlash) as IconProp}
                    />
                  </span>
                </span>
                {errorMessage !== "" && (
                  <div className="d-flex flex-row col-10 text-center align-items-center justify-content-center mt-5 color-red">
                    {parse(errorMessage)}
                  </div>
                )}
                <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-7">
                  <button
                    type="submit"
                    disabled={
                      currentPwd === "" || newPwd === "" || confirmNew === ""
                    }
                    className={`${styles.submitBtn} d-flex flex-row align-items-center justify-content-center fw-bold text-xxl col-7 col-xl-4`}
                  >
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordPopUp;
