import React, { useState } from "react";

import styles from "./ChangeEmailPopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import parse from "html-react-parser";

interface ChangeEmailPopUpProps {
  open: boolean;
  close: () => void;
}

const ChangeEmailPopUp: React.FC<ChangeEmailPopUpProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setMessage("");
    setErrorMessage("");

    await axiosHelper
      .updateEmail(email)
      .then((response) => {
        // console.log(response);
        setMessage(`Now verify your new email: ${email}`);
      })
      .catch((error) => {
        // console.log(error);
        setErrorMessage(error.response.data.msg);
      });
    // console.log("submit");
  };

  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => {
                setEmail("");
                setErrorMessage("");
                props.close();
              }}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content}  col-12 `}>
              <span className="d-flex flex-row col-12 align-items-center justify-content-center light-title fw-bold mt-3">
                Update Your Email
              </span>

              <form
                onSubmit={handleSubmit}
                className="d-flex flex-column align-items-center justify-content-center col-12 "
              >
                <span className="d-flex flex-row col-12 align-items-center justify-content-center sub-title mt-7">
                  New Email
                </span>
                <span
                  className={`d-flex flex-row col-10 col-xl-6 align-items-center justify-content-center  mt-3 ${styles.inputContainer}`}
                >
                  <input
                    className="col-12  text-xxl text-center"
                    value={email}
                    type="email"
                    placeholder="Insert new email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </span>

                {errorMessage !== "" && (
                  <div className="d-flex flex-row col-10 text-center align-items-center justify-content-center mt-5 color-red">
                    {parse(errorMessage)}
                  </div>
                )}
                {message !== "" && (
                  <div className="d-flex flex-row col-10 text-center align-items-center justify-content-center mt-5 color-black">
                    {parse(message)}
                  </div>
                )}

                <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-7">
                  <button
                    type="submit"
                    disabled={email === ""}
                    className={`${styles.submitBtn} d-flex flex-row align-items-center justify-content-center fw-bold text-xxl col-7 col-xl-4`}
                  >
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ChangeEmailPopUp;
