import React, { useEffect, useState } from "react";

import styles from "./AboutMePopUp.module.scss";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axiosHelper from "../../helpers/axiosHelper";
import BusinessPlatformStore, {
  setPlatformContact,
} from "../../store/BusinessPlatformStore";
import { useStoreState } from "pullstate";
import { getPlatformContact, getPlatformUser } from "../../store/Selector";

interface AboutMePopUpProps {
  firstName: string;
  lastName: string;
  phonenumber: string;
  email: string;
  prefix: string;
  open: boolean;
  close: () => void;
}

const AboutMePopUp: React.FC<AboutMePopUpProps> = (props) => {
  const user = useStoreState(BusinessPlatformStore, getPlatformUser);
  const contact = useStoreState(BusinessPlatformStore, getPlatformContact);
  const [popup, setPopup] = useState(false);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phonenumber);
  const [prefix, setPrefix] = useState(props.prefix);
  const [prefixError, setPrefixError] = useState(false);

  const handlePrefix = (e: string) => {
    if (!e.startsWith("+")) {
      setPrefix("+" + e);
    } else {
      setPrefix(e);
    }
  };

  useEffect(() => {
    prefix && prefix.length > 1 && setPrefixError(false);
  }, [prefix]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (prefix && prefix.length && prefix.length < 2) {
      setPrefixError(true);
    } else {
      // // console.log("submit");
      let data: updatePersonalInformationData = {
        name: firstName,
        surname: lastName,
        phone: phone,
        prefix: prefix,
        email: email,
      };
      // console.log(data);
      if (user.user_type !== "G") {
        await axiosHelper
          .updatePersonalInformations(data)
          .then((response) => {
            // console.log(response.updated);
            setPlatformContact(response.updated);
            props.close();
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        await axiosHelper
          .updatePersonalInformationsGod(data, contact.uuid)
          .then((response) => {
            // console.log(response.updated);
            setPlatformContact(response.updated);
            props.close();
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
  };
  return (
    <>
      <Modal
        show={props.open}
        onHide={() => {
          setPopup(!popup);
          props.close();
        }}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start  color-black 
            ${styles.modalBody}
            `}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div className={`${styles.content} col-12   `}>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 pt-5 pt-xl-2">
                <span className="light-title fw-bold">
                  About me informations
                </span>
              </div>
              <form onSubmit={handleSubmit} className="col-12">
                <div className="d-flex flex-column align-items-start justify-content-start mt-7  col-12">
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start  ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      First Name
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="text"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={firstName}
                        required
                        placeholder="Insert your first name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Last Name
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="text"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={lastName}
                        required
                        placeholder="Insert your last name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Email
                    </span>
                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <input
                        type="email"
                        className={`ps-xl-5 ${styles.inputField}`}
                        value={email}
                        placeholder="Insert your email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </span>
                  </div>
                  <div
                    className={`d-flex flex-row col-12 align-items-center justify-content-start mt-5 ${styles.inputRow}`}
                  >
                    <span className="col-4  text-center  d-flex flex-row align-items-center text-xxl  ">
                      Mobile number
                    </span>

                    <span
                      className={`col-8  text-center  d-flex flex-row align-items-center text-xxl ${styles.inputContainer}`}
                    >
                      <span
                        className={`col-3 d-flex flex-row align-items-center justify-content-center ${styles.prefix}`}
                      >
                        <input
                          type="text"
                          className={`ps-xl-5 col-3  ${styles.inputField}`}
                          value={prefix}
                          placeholder=" prefix "
                          onChange={(e) => handlePrefix(e.target.value)}
                        />
                      </span>
                      <input
                        type="text"
                        className={`ps-xl-5 col-9  ${styles.inputField}`}
                        value={phone}
                        placeholder="mobile number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </span>
                  </div>
                  {prefixError && (
                    <div
                      className="d-flex flex-row align-items-center justify-content-center col-12 text pt-5 text-center"
                      style={{ color: "red" }}
                    >
                      <span className="col-8">
                        Please insert the prefix of your mobile number
                      </span>
                    </div>
                  )}
                  <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-5 ">
                    <button
                      type="submit"
                      className={`${styles.submitBtn} col-xl-3 col-5 text-xxl fw-bold`}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AboutMePopUp;
